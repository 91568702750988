<template>
  <div v-if="isVisible" class="dizme_tm_section">
    <div class="dizme_tm_subscribe">
      <div class="container">
        <div class="inner">
          <div class="background">
            <div class="dots" data-img-url="/img/subscribe/dots.jpg"></div>
            <div class="overlay"></div>
          </div>
          <div class="content">
            <div class="left wow fadeInLeft" data-wow-duration="1s">
              <span class="subtitle">Subscribe Now</span>
              <h3 class="title">Get My Newsletter</h3>
              <p class="text">
                Get latest news, updates, tips and trics in your inbox
              </p>
            </div>
            <div class="right wow fadeInRight" data-wow-duration="1s">
              <div class="field">
                <input type="text" placeholder="Your email here" />
                <input type="submit" value="Send Now" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SubscribeComponent",
  components: {},
};
</script>
