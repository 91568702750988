<template>
  <div class="dizme_tm_all_wrap" data-magic-cursor="show">
    <MobileHeaderVue dark />
    <HeaderVue dark />
    <ContactComponent />
    <CopyrightComponent />
  </div>
</template>

<script>
import HeaderVue from "@/components/layout/Header.vue";
import MobileHeaderVue from "@/components/layout/MobileHeader.vue";
import ContactComponent from "@/components/ContactComponent.vue";
import CopyrightComponent from "@/components/CopyrightComponent.vue";
export default {
  name: "WeddingDay",
  components: {
    CopyrightComponent,
    ContactComponent,
    MobileHeaderVue,
    HeaderVue,
  }
};
</script>
