<template>
  <div class="dizme_tm_section" id="მთავარი">
    <div class="dizme_tm_hero">
      <div
        class="background"
        :data-img-url="`/img/slider/${dark ? 2 : 1}.jpg`"
      ></div>
      <div class="container">
        <div class="content">
          <div class="details">
            <div class="hello">
              <h3 class="orangeText">გამარჯობა, მე ვარ</h3>
            </div>
            <div class="name">
              <h3>ვალერა ჟვანია</h3>
            </div>
            <div class="job">
              <h4><b>ვიდეოგრაფი</b></h4>
            </div>
            <div class="text">
              <p>
                ჩემი კარიერა 2012 წელს დაიწყო. 
                </p>
              <p>
                გადაღებული მაქვს 400-ზე მეტი ქორწილი.
                </p>                 
            </div>
            <div class="button">
              <div class="dizme_tm_button">
                <a class="anchor" href="#ჩემს_შესახებ"><span>ჩემს შესახებ</span></a>
              </div>
              <div class="social">
                <ul>
                  <li>
                    <a href="https://www.facebook.com/valerazhvania" target="_blank"><i class="icon-facebook-1"></i></a>
                  </li>
                  <li>
                    <a href="https://www.youtube.com/@Valerazhvania"><i class="icon-youtube-1"></i></a>
                  </li>
                  <li>
                    <a href="https://wa.me/+995598780140"><i class="icon-Whatsapp-1"></i></a>
                  </li>
                  <li>
                    <a href="mailto:valerazhvania@gmail.com"><i class="icon-gmail"></i></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="avatar">
            <div class="image">
              <img src="/img/slider/avatar.png" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="dizme_tm_down">
        <a class="anchor" href="#ჩემს_შესახებ" v-html="dark ? mouseDark : mouse">
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { ai, figma, mouse, mouseDark, ps } from "../svg";
export default {
  name: "HomeComponent",
  data() {
    return { ai, ps, figma, mouse, mouseDark };
  },
  props: {
    dark: { type: Boolean },
  },
};
</script>
