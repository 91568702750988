<template>
  <div class="dizme_tm_section">
    <div class="dizme_tm_testimonials">
      <div class="dizme_tm_main_title" data-align="center">
        <span>შეფასებები</span>
        <h3>თბილი სიტყვები</h3>
        <p>
          ეს სიტყვები მაძლევს მოტივაციას ვაკეთო კიდევ
          უკეთესი.
        </p>
      </div>
      <div class="list_wrapper">
        <div class="total">
          <div class="in">
            <swiper
              :pagination="{ clickable: true, el: '.owl-dots' }"
              :modules="modules"
              class="owl-carousel owl-theme"
            >
              <swiper-slide>
                <div class="icon" v-html="testimonialIcon"></div>
                <div class="text">
                  <p>
                    მე ბევრს ვფიქრობდი თუ როგორი გამოვიდოდა ვიდეო, ყველა
                    იმ ნიუანსის გათვალისწინებით რაც ამ დღეს დაემთხვა
                    სიმართლეს გეტყვი, მე რომ ახლა ვირჩევდე ვიდეომომსახურებას
                    კვლავ შენზე შევაჩერებდი არჩევანს. შენ მაქსიმუმი გააკეთე
                    და შენით უაღრესად კმაყოფილი ვარ.
                  </p>
                </div>
                <div class="short">
                  <div class="image">
                    <div
                      class="main"
                      data-img-url="/img/testimonials/T6.jpg"
                    ></div>
                  </div>
                  <div class="detail">
                    <h3>თორნიკე</h3>
                    <span></span>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="icon" v-html="testimonialIcon"></div>
                <div class="text">
                  <p>
                    ვალერა, უღრმესი მადლობა. ნამდვილად მაღალი
                    პროფესიონალიზმით არის შესრულებული. ძალიან
                    კმაყოფილი და ბედნიერი ვარ🧡. ზუსტად ასეთი მინდოდა.
                    უგახარებულესი ვარ 😁
                  </p>
                </div>
                <div class="short">
                  <div class="image">
                    <div
                      class="main"
                      data-img-url="/img/testimonials/T5.jpg"
                    ></div>
                  </div>
                  <div class="detail">
                    <h3>ნანა</h3>
                    <span></span>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="icon" v-html="testimonialIcon"></div>
                <div class="text">
                  <p>
                    აუუუ რა კარგია, ლამის ვიტირე💖 ძალიან მომწონს
                    ასეთს არ ველოდი, იდეალურია ყველაფერი.
                    ძაან გაგვიმართლა შენ რომ შეგარჩიე და ასე 
                    ლამაზად გამოვიდა ყველაფერი🧡
                    ისე მომეწონა
                  </p>
                </div>
                <div class="short">
                  <div class="image">
                    <div
                      class="main"
                      data-img-url="/img/testimonials/T4.jpg"
                    ></div>
                  </div>
                  <div class="detail">
                    <h3>ნინი</h3>
                    <span></span>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="icon" v-html="testimonialIcon"></div>
                <div class="text">
                  <p>
                    ოქრო ხარ ოქრო. აი ძაან მომწონს. მუსიკაც არის საოცრება
                    უღრმესი მადლობა🧡. გავგიჟდი მართლა ცრემლები მომადგა
                    ისე მომეწონა
                  </p>
                </div>
                <div class="short">
                  <div class="image">
                    <div
                      class="main"
                      data-img-url="/img/testimonials/T3.jpg"
                    ></div>
                  </div>
                  <div class="detail">
                    <h3>მარიამი</h3>
                    <span></span>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="icon" v-html="testimonialIcon"></div>
                <div class="text">
                  <p>
                    ვალერა, ძააალიან მომეწონა 😍🧡უღრმესი მადლობა კიდევ
                    ერთხელ! სიმღერები ძალიან გემოვნებიანად გაქვს შერჩეული,
                    კადრებიც ძალიან ლამაზი გამოვიდა 😍 დიდი, დიდი მადლობა!
                    ფაქტია იცი შენი საქმე და კიდევ უფრო წარმატებებს გიფურვებ💗
                  </p>
                </div>
                <div class="short">
                  <div class="image">
                    <div
                      class="main"
                      data-img-url="/img/testimonials/T2.jpg"
                    ></div>
                  </div>
                  <div class="detail">
                    <h3>ჯოვანა</h3>
                    <span></span>
                  </div>
                </div>
              </swiper-slide>
              <swiper-slide>
                <div class="icon" v-html="testimonialIcon"></div>
                <div class="text">
                  <p>
                    ძალიან დიდი მადლობა 😍 ძალიან მაგარი ვიდეოა 😍
                    ერთი სიამოვნება იყო შენთან ერთად მუშაობა ქორწილში,
                    ვნერვიულობდი ძალიან და შენ ძალიან გაგვამხიარულე
                    და შეგვიმსუბუქე ეგ დღე. მართლა ძალიან დიდი მადლობა
                    რომ ასეთი კომფორტული იყო შენთან მუშაობა. 🥰
                  </p>
                </div>
                <div class="short">
                  <div class="image">
                    <div
                      class="main"
                      data-img-url="/img/testimonials/T1.jpg"
                    ></div>
                  </div>
                  <div class="detail">
                    <h3>ნინო</h3>
                    <span></span>
                  </div>
                </div>
              </swiper-slide>
            </swiper>
            <div class="owl-dots"></div>
          </div>
          <div class="left_details">
            <div
              class="det_image one wow fadeIn"
              data-wow-duration="1s"
              data-img-url="/img/testimonials/T4.jpg"
            ></div>
            <div
              class="det_image two wow fadeIn"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
              data-img-url="/img/testimonials/T5.jpg"
            ></div>
            <div
              class="det_image three wow fadeIn"
              data-wow-duration="1s"
              data-wow-delay="0.4s"
              data-img-url="/img/testimonials/T2.jpg"
            ></div>
            <div
              class="det_image four wow fadeIn"
              data-wow-duration="1s"
              data-wow-delay="0.6s"
              data-img-url="/img/testimonials/T3.jpg"
            ></div>
            <span class="circle green animPulse"></span>
            <span class="circle yellow animPulse"></span>
            <span class="circle border animPulse"></span>
          </div>
          <div class="right_details">
            <div
              class="det_image one wow fadeIn"
              data-wow-duration="1s"
              data-img-url="/img/testimonials/T6.jpg"
            ></div>
            <div
              class="det_image two wow fadeIn"
              data-wow-duration="1s"
              data-wow-delay="0.2s"
              data-img-url="/img/testimonials/T1.jpg"
            ></div>
            <div
              class="det_image three wow fadeIn"
              data-wow-duration="1s"
              data-wow-delay="0.4s"
              data-img-url="/img/testimonials/T3.jpg"
            ></div>
            <span class="circle yellow animPulse"></span>
            <span class="circle purple animPulse"></span>
            <span class="circle border animPulse"></span>
          </div>
        </div>
      </div>
      <div class="brush_1 wow fadeInRight" data-wow-duration="1s">
        <img src="/img/brushes/testimonials/1.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { testimonialIcon } from "@/svg";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
export default {
  name: "TestimonialsComponent",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      testimonialIcon,
    };
  },
  setup() {
    return {
      modules: [Pagination],
    };
  },
};
</script>
