<template>
  <div class="dizme_tm_section" id="ჩემს_შესახებ">
    <div class="dizme_tm_about">
      <div class="container">
        <div class="wrapper">
          <div class="left">
            <div class="image">
              <img :src="`/img/about/${dark ? 2 : 1}.png`" alt="" />
              <div class="numbers year">
                <div class="wrapper">
                  <h3>
                    <VueJsCounter end="12"></VueJsCounter>
                  </h3>
                  <span class="name">წელი</span>
                </div>
              </div>
              <div class="numbers project">
                <div class="wrapper">
                  <h3 style="display: flex">
                    <VueJsCounter as="span" end="400"></VueJsCounter><p>+</p>
                  </h3>
                  <span class="name">ქორწილი</span>
                </div>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="title wow fadeInUp" data-wow-duration="2s">
              <span>მე ვარ ვიდეოგრაფი</span>
              <h3></h3>
            </div>
            <div class="text wow fadeInUp" data-wow-duration="2s">
              <p> 
                ჩემთვის ქორწილის გადაღება არა მხოლოდ პროფესია, არამედ
                ხელოვნებაა. ვცდილობ თითოეული წყვილის განსაკუთრებული,
                საინტერესო და ემოციური მომენტები სრულყოფილად ავღბეჭდო. </p>
              <p> ყოველდღიურად ვცდილობ საკუთარ ცოდნასა და უნარებში
                სიახლეების შეტანას, რათა მუდმივად გავაუმჯობესო ჩემი
                სამუშაო და უზრუნველვყო ყველაზე მაღალხარისხიანი მომსახურება. </p>
              <p> ამჟამად ვმუშაობ მოწინავე ტექნოლოგიებთან და თანამედროვე
                კამერებთან, რაც შესაძლებლობას მაძლევს, შევქმნა მაღალი
                ხარისხის კადრები, რომლებიც ცოცხლად ასახავს თითოეული
                წყვილის განსაკუთრებულ მომენტებს. </p>
              <p> ვიდეოგრაფია ჩემთვის არის საშუალება, გავიზიარო სხვისი
                ბედნიერება და შევქმნა არამარტო სილამაზის,
                არამედ ემოციის სრული სურათი.
                ჩემთვის ყველაზე დიდი სიამოვნება არის ის, როცა ვხედავ,
                რომ ჩემი ვიდეოები ადამიანებს ბედნიერებას ანიჭებს. </p>
              <p> დიდი სიამოვნებით მივიღებ თქვენს იდეებს და
                აუცილებლად შევქმნით ერთად განსაკუთრებულ კადრებს თქვენი
                ცხოვრების მნიშვნელოვან მომენტზე. </p>
            </div>
            <div class="dizme_tm_button wow fadeInUp" data-wow-duration="2s">
              <a class="anchor" href="#პორტფოლიო"><span>პორტფოლიო</span></a>
            </div>
          </div>
        </div>
      </div>
      <div class="brush_1 wow fadeInLeft" data-wow-duration="2s">
        <img src="/img/brushes/about/1.png" alt="" />
      </div>
      <div class="brush_2 wow fadeInRight" data-wow-duration="2s">
        <img src="/img/brushes/about/2.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import VueJsCounter from "vue-js-counter";
export default {
  name: "AboutComponent",
  components: { VueJsCounter },
  props: {
    dark: { type: Boolean },
  },
};
</script>
